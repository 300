.Authorize-main{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(54, 55, 64, 1);
}

.Authorize-content{
    position: relative;
    Width: 380px;
    Height: 582px;
    top: 139px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    /* grayscale / white */

    background: #FFFFFF;
    /* grayscale / divider */

    border: 1px solid #DFE0EB;
    border-radius: 8px;
}
.Authorize-logo{
    width: 48px;
    height: 48px;
    margin-left: calc(50% - 48px/2);
    margin-top: 40px;
}
.Authorize-title{
    /* Bold/19px */
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.4px;

    /* sidebar / gray */

    color: #A4A6B3;
}
.Authorize-form-title{
    /* Bold/24px */
    margin-top: 40px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.3px;

    /* grayscale / black */

    color: #252733;

}
.Authorize-form-subtitle{
    /* Regular/14px */

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-align: center;
    letter-spacing: 0.3px;

    /* grayscale / gray */

    color: #9FA2B4;
}
.Authorize-form{
    width: 80%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}

.Authorize-button{
    background-color: rgba(55, 81, 255, 1);
    width: 100%;
}

.Authorize-form-label{
    /* Bold/12px */

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    text-align: left;
    /* grayscale / gray */
    padding-bottom: 10px;
    color: #9FA2B4;
}