.Logout-main{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(54, 55, 64, 1);
}

.Logout-content{
    position: relative;
    Width: 250px;
    min-height: 150px;
    top: 150px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    /* grayscale / white */

    background: #FFFFFF;
    /* grayscale / divider */

    border: 1px solid #DFE0EB;
    border-radius: 8px;
}

.Logout-subcontent{
    position: relative;
    height: 100px;
    margin-top: 20%;
    margin-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}