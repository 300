.logo {
    height: 48px;
    margin: 1px;
    width: 48px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }
    [data-theme="dark"] .site-layout .site-layout-background {
      background: #141414;
    }